import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  Input,
  InputLabel,
  FormControl,
  Divider,
  Tooltip,
  CircularProgress,
  Typography,
  Modal,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Table, {
  SelectColumnFilter,
} from "../../../components/customTable/Table";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { alertSuccess, alertError } from "../../../../utils/logger";
import {
  syncPropertiesGolzoom,
  syncOctoparse,
  getAllOctoparse,
} from "../../../../api/property";
import { getTasks } from "../../../../api/tasks";
import { getUsers } from "../../../../api/user";
import { getProperties } from "../../../../api/property";
import { updateProperty } from "../../../../api/property";

const getData = (properties, tasks) => {
  console.log("OCTOPARSE PROPERTIES: ", properties);
  console.log("DATABASE TASKS: ", tasks);

  let data = [];
  for (let i = 0; i < properties?.length; i++) {
    const elem = {};
    const task = tasks.find((item) => item.taskId === properties[i].taskId);
    elem._id = properties[i].taskId;
    elem.title = properties[i].nombre;
    elem.status =
      properties[i].data.length > 0
        ? "Completado"
        : "En preparación de ejecución";
    elem.total = properties[i].data.length;
    elem.importado = task?.taskImported
      ? `La tarea fue importada por última vez el ${new Date(
          task?.updatedAt
        ).toLocaleString("es-ES", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })}`
      : "La tarea no ha sido importada";
    elem.properties = properties[i].data?.length > 0 ? properties[i].data : [];
    data = data.concat(elem);
  }
  return data;
};

export default function ImportarPropiedadesPage() {
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [properties, setProperties] = useState([]);
  const [userAssigned, setUserAssigned] = useState(null);
  const [openModal, setOpenModal] = useState(0);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isLoading, setIsLoading] = useState(3);
  const [loadingPage, setLoadingPage] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    // const fetchData = async () => {
    //   try {
    //     const [octoparseRes, tasksRes, usersRes] = await Promise.all([
    //       getAllOctoparse(),
    //       getTasks(),
    //       getUsers(),
    //       getProperties(),
    //     ]);
    //     if (
    //       octoparseRes.status !== 200 ||
    //       tasksRes.status !== 200 ||
    //       usersRes.status !== 200
    //     ) {
    //       throw new Error("Error fetching one or more data sources");
    //     }
    //     setData(getData(octoparseRes.data, tasksRes.data));
    //     setUsers(usersRes.data);
    //     setLoadingPage(false);
    //   } catch (err) {
    //     alertError({
    //       err,
    //       customMessage: err.message.includes("Octoparse")
    //         ? "Ha ocurrido un error tratando de obtener los datos de Octoparse."
    //         : err.message.includes("tasks")
    //         ? "Ha ocurrido un error tratando de obtener los datos de tareas."
    //         : err.message.includes("users")
    //         ? "Ha ocurrido un error tratando de obtener los usuarios."
    //         : "Ha ocurrido un error tratando de obtener las propiedades.",
    //     });
    //   }
    // };
    // fetchData();
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const startGolzoomSync = async () => {
    if (!file) {
      alertError({ customMessage: "Please select a file to upload." });
      return;
    }
    setIsLoading(0);
    try {
      const formData = new FormData();
      formData.append("file", file);

      // Log the FormData content
      for (let key of formData.keys()) {
        console.log(key, formData.get(key));
      }

      await syncPropertiesGolzoom(formData);
      alertSuccess({ customMessage: "File uploaded successfully!" });
    } catch (error) {
      alertError({
        customMessage: "An error occurred while uploading the file.",
      });
    } finally {
      setIsLoading(3);
    }
  };

  const startOctoparseSync = async (taskId) => {
    try {
      setIsLoading(1);
      const taskName = data.find((item) => item._id === taskId).title;
      const res = await syncOctoparse(taskId, taskName);
      if (res.status === 200) {
        alertSuccess({
          customMessage: "Synchronization started successfully!",
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        alertError({
          customMessage:
            "An error occurred while starting the synchronization.",
        });
      }
    } catch (error) {
      alertError({
        customMessage: "An error occurred while starting the synchronization.",
      });
    } finally {
      setIsLoading(3);
    }
  };

  const assignPropertiesToUser = async () => {
    if (!userAssigned) {
      alertError({ customMessage: "Please select a user to assign." });
      return;
    }

    //selectedTask.properties has to be paired with the properties by the properties.origin and the selectedTask.properties.URL_ingresadas
    const propertiesToUpdate = selectedTask.properties.map((property) => {
      const foundProperty = properties.find(
        (item) => item.origin === property.URL_ingresadas
      );
      return {
        foundProperty,
        assignedUser: userAssigned._id,
      };
    });

    propertiesToUpdate.forEach((p) => {
      updateProperty(p.foundProperty._id, { assignedUser: p.assignedUser })
        .then((res) => {
          console.log("PROPERTY UPDATED: ", res);
        })
        .catch((err) => {
          alertError({
            customMessage: `Error updating property. ${p.property}`,
          });
        });
    });

    alertSuccess({ customMessage: "Properties assigned successfully!" });
    setUserAssigned(null);
  };

  function buttonFormatter(cell) {
    const elem = data.find((item) => item._id === cell);
    console.log(("ELEM: ", elem));
    return (
      <span className="d-flex flex-row">
        <Tooltip title="Importar datos al sistema">
          <Button
            variant="contained"
            className="btn btn-primary m-5"
            onClick={() => startOctoparseSync(cell)}
            disabled={isLoading === 1}
          >
            Sincronizar
            <ImportExportIcon />
          </Button>
        </Tooltip>
        {elem?.total > 0 && elem.importado.includes("fue importada") ? (
          <Tooltip title="Asignar usuario">
            <Button
              variant="contained"
              className="btn btn-primary m-5"
              onClick={() => {
                setOpenModal(2);
                setSelectedTask(elem);
              }}
            >
              <PersonAddIcon />
            </Button>
          </Tooltip>
        ) : null}
      </span>
    );
  }

  const listFormatter = (properties, taskId) => {
    return (
      <div
        onClick={() => {
          setOpenModal(1);
          const item = data.find((item) => item._id === taskId);
          setSelectedTask(item);
        }}
        style={{ cursor: "pointer", display: "flex", flexDirection: "column" }}
      >
        {properties?.slice(0, 3).map((property, index) => (
          <span key={index}>{property?.Titulo?.slice(0, 35)}...</span>
        ))}
        {properties?.length > 3 ? (
          <span>...</span>
        ) : (
          <span>-----------------------</span>
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Título",
        accessor: "title",
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
      },
      {
        Header: "Propiedades",
        accessor: "properties",
        disableFilters: true,
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
        Cell: ({ cell: { value }, row: { original } }) =>
          listFormatter(value, original._id),
      },
      {
        Header: "Estado",
        accessor: "status",
        Filter: SelectColumnFilter,
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
      },
      {
        Header: "Importado",
        accessor: "importado",
        Filter: SelectColumnFilter,
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
      },
      {
        Header: "Total",
        accessor: "total",
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
      },
      {
        Header: "",
        accessor: "_id",
        disableFilters: true,
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
        Cell: ({ cell: { value } }) => buttonFormatter(value),
      },
    ],
    [data]
  );

  return (
    <>
      {loadingPage ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h4">Loading...</Typography>
          <CircularProgress size={50} className="m-5" />
        </div>
      ) : (
        <Card>
          <CardHeader title="Sincronización de propiedades de Octoparse y de Golzoom" />
          <CardBody>
            <h3 style={{ fontSize: "20px" }}>
              Importar propiedades de Golzoom
            </h3>
            <div className="d-flex justify-content-between align-items-center">
              <p
                style={{ textAlign: "justify", fontSize: "16px" }}
                className="w-25 m-5"
              >
                <b>
                  Selecciona el archivo que desees importar y presiona el botón
                  sincronizar. Importante, recuerda que el excel sea puro
                  exportado de Golzoom y que no tenga modificaciones, ya que
                  puede afectar a la sincronización de propiedades.
                </b>
              </p>
              <FormControl className="w-25 m-5" fullWidth>
                <InputLabel htmlFor="file-upload">Upload File</InputLabel>
                <Input
                  id="file-upload"
                  type="file"
                  inputProps={{ accept: ".xlsx, .xls" }}
                  onChange={handleFileChange}
                />
              </FormControl>
              <Button
                variant="contained"
                className="btn btn-primary m-5"
                onClick={startGolzoomSync}
                disabled={isLoading === 0}
              >
                Sincronizar
              </Button>
              {isLoading === 0 && (
                <div className="spinner-border text-primary m-5" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </div>
            <Divider />
            <br />
            <br />
            {/*<h3 style={{ fontSize: "20px" }}>
              Sincronización de propiedades de Octoparse
            </h3>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <section style={{ textAlign: "justify", fontSize: "16px" }}>
                <p>
                  Para la correcta sincronización de datos del Octoparse, sigue
                  los siguientes pasos
                </p>
                <ol>
                  <li>Ingresa en el Octoparse.</li>
                  <li>
                    Busca las diferentes urls genéricas que quieras introducir
                    en idealista con los filtros que desees. Ejemplo:
                    https://www.idealista.com/venta-viviendas/barcelona/eixample/
                    e inicia el proceso del primer scrapping (Idealista Listing
                    Pages Scraper).
                  </li>
                  <li>
                    Descárgate el excel de la genérica y copia todas las urls
                    específicas de los inmuebles que hayas encontrado y
                    prepárate para ejecutar la plantilla de detalles de
                    Octoparse con los enlaces que has encontrado.
                  </li>
                  <li>
                    Si lo has hecho correctamente, debería aparecer un nuevo
                    registro en la tabla de Octoparse con la información de la
                    propiedad.
                  </li>
                  <br />
                  <h4 className="text-danger">
                    El sistema tendrá en cuenta TODAS LAS PLANTILLAS QUE
                    CONTENGAN "Details", asegúrate de que los procesos aparecen
                    como "completados" en Octoparse.
                  </h4>
                </ol>
              </section>
              <Table data={data} columns={columns} />
            </div>
            {/* MODAL DE PROPIEDADES PARA CADA EXPORTACIÓN 
            <Modal
              open={openModal === 1}
              onClose={() => {
                setOpenModal(null);
              }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 100,
              }}
            >
              <div className="bg-white rounded p-3 w-75 h-50 overflow-auto">
                <span className="d-flex flex-row justify-content-between">
                  <h4>Propiedades:</h4>
                </span>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Titulo</th>
                      <th>URL Ingresadas</th>
                      <th>Precio</th>
                      <th>Características Destacadas</th>
                      <th>Ubicación</th>
                      <th>Comentario del Anunciante</th>
                      <th>Características Básicas</th>
                      <th>Edificio</th>
                      <th>Equipamiento</th>
                      <th>Certificado Energético</th>
                      <th>Ref</th>
                      <th>Contacto URL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedTask?.properties.map((property, index) => (
                      <tr key={index}>
                        <td>{property.Titulo}</td>
                        <td>{property.URL_ingresadas}</td>
                        <td>{property.Precio}</td>
                        <td>{property.Características_destacadas}</td>
                        <td>{property.Ubicación}</td>
                        <td>{property.Comentario_del_anunciante}</td>
                        <td>{property.Características_básicas}</td>
                        <td>{property.Edificio}</td>
                        <td>{property.Equipamiento}</td>
                        <td>{property.Certificado_energetico}</td>
                        <td>{property.Ref}</td>
                        <td>{property.Contacto_URL}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <span className="d-flex flex-row justify-content-between">
                  <h4>Propiedades:</h4>
                </span>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Titulo</th>
                      <th>URL Ingresadas</th>
                      <th>Precio</th>
                      <th>Características Destacadas</th>
                      <th>Ubicación</th>
                      <th>Comentario del Anunciante</th>
                      <th>Características Básicas</th>
                      <th>Edificio</th>
                      <th>Equipamiento</th>
                      <th>Certificado Energético</th>
                      <th>Ref</th>
                      <th>Contacto URL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedTask?.properties.map((property, index) => (
                      <div key={index} className="d-flex flex-row">
                        <tr>
                          <td>{property.Titulo}</td>
                          <td>{property.URL_ingresadas}</td>
                          <td>{property.Precio}</td>
                          <td>{property.Características_destacadas}</td>
                          <td>{property.Ubicación}</td>
                          <td>{property.Comentario_del_anunciante}</td>
                          <td>{property.Características_básicas}</td>
                          <td>{property.Edificio}</td>
                          <td>{property.Equipamiento}</td>
                          <td>{property.Certificado_energetico}</td>
                          <td>{property.Ref}</td>
                          <td>{property.Contacto_URL}</td>
                        </tr>
                      </div>
                    ))}
                  </tbody>
                </table>
              </div>
            </Modal>
            {/* MODAL DE ASIGNACIÓN DE USUARIOS 
            <Modal
              open={openModal === 2}
              onClose={() => {
                setOpenModal(null);
              }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 100,
              }}
            >
              <div className="bg-white rounded p-3 w-25 h-50 overflow-auto d-flex flex-column justify-content-center align-items-center">
                <span className="d-flex flex-row justify-content-between">
                  <h4 className="p-2 m-2">Asignar usuario:</h4>
                </span>
                <Autocomplete
                  options={users}
                  getOptionLabel={(option) => option.fullName}
                  style={{ width: 300 }}
                  onChange={(event, newValue) => {
                    setUserAssigned(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Usuarios"
                      variant="outlined"
                    />
                  )}
                  className="m-5"
                />
                <Button
                  variant="contained"
                  className="btn btn-primary m-5"
                  onClick={() => {
                    assignPropertiesToUser();
                    setOpenModal(null);
                  }}
                >
                  Asignar
                </Button>
              </div>
            </Modal>
            <br />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                padding: "20px",
                borderRadius: "10px",
                transform: "translate(-50%, -50%)",
                display: isLoading === 1 ? "block" : "none",
              }}
            >
              <span className="d-flex flex-column justify-content-center align-items-center">
                <Typography variant="h4">Loading...</Typography>
                <CircularProgress size={50} className="mt-5" />
              </span>
            </div>
            */}
          </CardBody>
        </Card>
      )}
    </>
  );
}
