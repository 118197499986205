import { authClient, API } from "../index";
import { uploadSingleFile, uploadFilesGetLinks } from "../files";

// Get all properties
export const getProperties = ({ page, limit, filters, sortBy, order }) => {
  return authClient().get(`${API}/property`, {
    params: { page, limit, ...filters, sortBy, order, getDisabled: true },
  });
};

// Get properties by userId with pagination and filters
export const getPropertiesByUserId = (
  userId,
  { page, limit, filters, sortBy, order }
) => {
  return authClient().get(`${API}/property/user/${userId}`, {
    params: { page, limit, sortBy, order, ...filters },
  });
};

// Get property by id
export const getPropertyById = (id) => {
  return authClient().get(`${API}/property/${id}`);
};

// Get batch of properties by ids
export const getPropertiesByIds = (ids) => {
  return authClient().post(`${API}/property/byIds`, { ids });
};

// Delete property
export const deleteProperty = (id) => {
  return authClient().delete(`${API}/property/${id}`);
};

// Create property
export const postProperty = async (property, gallery) => {
  if (gallery) {
    const filesURLs = await uploadFilesGetLinks(gallery, "properties");
    property.gallery = filesURLs;
  }
  return authClient().post(`${API}/property`, property);
};

// Update property
export const updateProperty = async (id, property) => {
  console.log("Property", property);
  return authClient().put(`${API}/property/${id}`, property);
};

// Change status property
export const changeStatusProperty = async (id, active) => {
  return authClient().put(`${API}/property/change-status/${id}`, { active });
};

export const countProperties = () => {
  return authClient().get(`${API}/property/count`);
};

//Sincroniza un proceso de octoparse por su id
export const syncOctoparse = async (taskId, taskName) => {
  return authClient().post(`${API}/property/octoparse/sync`, {
    taskId,
    taskName,
  });
};

export const getAllOctoparse = async () => {
  return authClient().get(`${API}/property/octoparse/sync`);
};

export const syncIndividualExtraPic = async (id) => {
  return authClient().post(`${API}/property/extractpics/load-images/${id}`);
};

export const syncPropertiesGolzoom = async (data) => {
  for (let key of data.keys()) {
    console.log(key, data.get(key));
  }
  return authClient().post(`${API}/property/golzoom/sync`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const syncIndividualAddresses = async (id, newData) => {
  return authClient().post(`${API}/property/addresses`, { id, newData });
};

export const getGroupCadastralReferences = async (data) => {
  return authClient().post(`${API}/property/cadastral-references`, data);
};
