import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteUser,
  getUserById,
  postUser,
  updateUser,
  handleoAuth,
} from "../../../../api/user";
import { getLocalidades } from "../../../../api/localidad";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyAdmin() {
  return {
    fullName: "",
    email: "",
    role: "",
    localidad: "",
    password: "",
    repeatPassword: "",
    active: true,
  };
}

export default function EditAdminsPage() {
  const [admin, setAdmin] = useState(getEmptyAdmin());
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [newPassword, setNewPassword] = useState({
    password: null,
    repeatPassword: null,
  });
  const [changePassword, setChangePassword] = useState(false);
  const user = useSelector((store) => store.authentication?.user);
  const adminId = useParams().id;
  const history = useHistory();
  const [localidades, setLocalidades] = useState([]);

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    if (!adminId) {
      disableLoadingData();
      return;
    }
    const fetchData = async () => {
      const response = await getUserById(adminId);
      if (response.status === 200) {
        setAdmin(response.data);
        disableLoadingData();
      } else {
        alertError({
          error: response,
          customMessage: "Could not fetch admin.",
        });
        disableLoadingData();
      }

      const responseLocalidades = await getLocalidades();
      if (responseLocalidades.status === 200) {
        setLocalidades(responseLocalidades.data);
      } else {
        alertError({
          error: responseLocalidades,
          customMessage: "Could not fetch localidades.",
        });
      }
    };

    fetchData();
  }, [adminId, disableLoadingData, history]);

  function saveAdmin() {
    let saveAdmin = admin;
    if (!adminId || changePassword) {
      if (!newPassword.password || !newPassword.repeatPassword) {
        alertError({
          error: null,
          customMessage: "Please enter the password.",
        });
        return;
      }
      if (newPassword.password !== newPassword.repeatPassword) {
        alertError({ error: null, customMessage: "Passwords do not match." });
        return;
      }
      saveAdmin = { ...saveAdmin, password: newPassword.password };
    }
    if (!adminId) {
      postUser(saveAdmin)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Admin successfully created.",
            });
            if (user.role === "admin") history.push("/admins");
            else history.push("/dashboard");
          }
        })
        .catch((error) => {
          alertError({ error: error, customMessage: "Could not save admin." });
        });
    } else {
      updateUser(adminId, saveAdmin)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Changes successfully saved.",
            });
            if (user.role === "admin") history.push("/admins");
            else history.push("/dashboard");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save changes.",
          });
        });
    }
  }

  const handleChange = (element) => (event) => {
    setAdmin({ ...admin, [element]: event.target.value });
  };

  const handleAuthorizeClick = () => {
    handleoAuth({ email: admin.email })
      .then((res) => {
        if (res.status === 200) {
          window.location.href = res.data.authUrl;
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not authorize Google Calendar.",
        });
      });
  };

  useEffect(() => {
    console.log("ADMIN LOCALIDAD: ", admin.localidad);
  }, [admin]);

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Edit admin"></CardHeader>
          <CardBody>
            <TextField
              id={`fullName`}
              label="Full name"
              value={admin.fullName}
              onChange={handleChange("fullName")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <TextField
              id={`email`}
              label="Email"
              value={admin.email}
              onChange={handleChange("email")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <Autocomplete
              id="localidad"
              options={localidades}
              getOptionLabel={(option) => option.fullName}
              value={
                admin.localidad
                  ? localidades.find((l) => l._id === admin.localidad) || null
                  : null
              }
              onChange={(event, newValue) => {
                setAdmin({
                  ...admin,
                  localidad: newValue ? newValue._id : null,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Localidad"
                  margin="normal"
                  variant="outlined"
                />
              )}
              clearOnEscape
            />
            <Select
              id={`role`}
              label="Role"
              value={admin.role}
              onChange={handleChange("role")}
              InputLabelProps={{
                shrink: true,
              }}
              className="w-25"
              margin="normal"
              variant="outlined"
              required
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="gestor">Gestor</MenuItem>
            </Select>
            {!adminId || changePassword ? (
              <>
                <br />
                <br />
                <TextField
                  id={`password`}
                  label="Password"
                  value={newPassword.password}
                  onChange={(event) => {
                    if (event.target.value !== " ")
                      setNewPassword({
                        ...newPassword,
                        password: event.target.value,
                      });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="password"
                  margin="normal"
                  variant="outlined"
                  required
                />
                <TextField
                  id={`repeatPassword`}
                  label="Repeat password"
                  value={newPassword.repeatPassword}
                  onChange={(event) => {
                    if (event.target.value !== " ")
                      setNewPassword({
                        ...newPassword,
                        repeatPassword: event.target.value,
                      });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="password"
                  margin="normal"
                  variant="outlined"
                  required
                />
                <br />
                <br />
                {adminId && (
                  <>
                    <Button
                      onClick={() => {
                        setChangePassword(false);
                        setNewPassword({
                          password: null,
                          repeatPassword: null,
                        });
                      }}
                      variant="outlined"
                      style={{ marginRight: "20px" }}
                    >
                      Cancel change password
                    </Button>
                    <br />
                    <br />
                  </>
                )}
              </>
            ) : (
              <>
                <br />
                <br />

                <Button
                  onClick={handleAuthorizeClick}
                  target="_blank"
                  color="primary"
                  variant="outlined"
                >
                  {adminId
                    ? !admin?.googleTokens || admin?.googleTokens === null
                      ? "Authorize Google Calendar"
                      : "Google Calendar authorized, Change account"
                    : "Authorize Google Calendar"}
                </Button>
                <br />
                <br />
                <Button
                  onClick={() => setChangePassword(true)}
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: "20px" }}
                >
                  Change password
                </Button>
                <br />
                <br />
              </>
            )}
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={admin.active}
                  onChange={() => setAdmin({ ...admin, active: !admin.active })}
                  name="checkActive"
                />
              }
              label="Active"
            />
          </CardBody>
        </Card>
        <Button
          onClick={() => saveAdmin()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Save admin
        </Button>
        {adminId && user.role === "admin" && (
          <>
            <Button
              onClick={() => history.push("/admins")}
              variant="outlined"
              style={{ marginRight: "20px" }}
            >
              Back
            </Button>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Delete admin
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"Are you sure you want to delete this admin?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteUser(adminId)
                  .then((res) => {
                    if (res.status === 204 || res.status === 200) {
                      alertSuccess({
                        title: "Deleted!",
                        customMessage: "Admin deleted successfully",
                      });
                      history.push("/admins");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not delete admin.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
}
