import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Card } from "react-bootstrap";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import {
  getProperties,
  getPropertiesByUserId,
  updateProperty,
} from "../../../../api/property";
import { getLocalidades } from "../../../../api/localidad";
import { getUsers } from "../../../../api/user";
import CommercialActivitiesPopUp from "../../../components/comercialActivities/ComercialActivitiesPopUp";
import PropertyCard from "../../../components/propertycard/PropertyCard";
import FiltersCard from "../../../components/propertycard/FiltersCard";
import { getEstadosPipeline } from "../../../../api/estadosPipeline";
import { alertError, alertSuccess } from "../../../../utils/logger";
import InfiniteScroll from "react-infinite-scroll-component";

export default function PipelinePage() {
  const [users, setUsers] = useState([]);
  const [properties, setProperties] = useState([]);
  const [filters, setFilters] = useState({ active: true });
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [showPopUp, setShowPopUp] = useState(false);
  const [currentPropertyId, setCurrentPropertyId] = useState(null);
  const [currentAction, setCurrentAction] = useState({});
  const [localidades, setLocalidades] = useState([]);

  const [page, setPage] = useState(1);
  const [pageSize] = useState(25);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const user = useSelector((state) => state.authentication.user);

  const fetchProperties = useCallback(
    async (currentPage = 1, currentFilters = filters) => {
      setLoading(true);
      setError(null);
      try {
        let res;
        if (user.role === "admin") {
          res = await getProperties({
            page: currentPage,
            limit: pageSize,
            filters: currentFilters,
            sortBy,
            order: sortOrder,
          });
        } else {
          res = await getPropertiesByUserId(user._id, {
            page: currentPage,
            limit: pageSize,
            filters: currentFilters,
            sortBy,
            order: sortOrder,
          });
        }

        if (res.status === 200) {
          const newProperties = res.data.data;
          console.log(`Fetched ${newProperties.length} properties`);
          setProperties((prev) =>
            currentPage === 1 ? newProperties : [...prev, ...newProperties]
          );
          setTotal(res.data.total);
        }
      } catch (error) {
        console.error("Error fetching properties:", error);
        setError("Failed to load properties.");
        alertError({
          customMessage: error.message || "Error fetching properties.",
        });
      } finally {
        setLoading(false);
      }
    },
    [user.role, user._id, pageSize, filters]
  );

  useEffect(() => {
    setProperties([]);
    setPage(1);
    fetchProperties(1, filters);
  }, [filters, fetchProperties]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await getUsers();
        if (res.data) {
          setUsers(res.data);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
        alertError({ customMessage: "Failed to fetch users." });
      }
    };

    const fetchLocalidades = async () => {
      try {
        const res = await getLocalidades();
        if (res.status === 200) {
          const filteredLocalidades = res.data.filter(
            (l) => l?.takeIntoAccount
          );
          setLocalidades(filteredLocalidades);
        }
      } catch (error) {
        console.error("Error fetching localidades:", error);
        alertError({ customMessage: "Failed to fetch localidades." });
      }
    };

    if (user._id) {
      fetchUsers();
      fetchLocalidades();
    }
  }, [user._id]);

  const [pipelineStates, setPipelineStates] = useState([]);

  useEffect(() => {
    const fetchPipelineStates = async () => {
      try {
        const res = await getEstadosPipeline();
        if (res.status === 200) {
          const sortedStates = res.data.sort((a, b) => a.order - b.order);
          setPipelineStates(sortedStates);
        }
      } catch (error) {
        console.error("Error fetching pipeline states:", error);
        alertError({ customMessage: "Failed to fetch pipeline states." });
      }
    };

    fetchPipelineStates();
  }, []);

  const columns = useMemo(() => {
    if (pipelineStates.length === 0) return {};

    const cols = {};
    pipelineStates.forEach((state) => {
      cols[state._id.toString()] = {
        name: state.name,
        items: [],
        color: state.color,
      };
    });

    properties.forEach((property) => {
      if (property.status && cols[property.status.toString()]) {
        cols[property.status.toString()].items.push({
          id: property._id.toString(),
          content: property.title,
        });
      }
    });

    return cols;
  }, [properties, pipelineStates]);

  const onDragEnd = async (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const propertyId = result.draggableId;
    const newStatus = destination.droppableId;

    setProperties((prev) =>
      prev.map((prop) =>
        prop._id === propertyId ? { ...prop, status: newStatus } : prop
      )
    );

    try {
      const res = await updateProperty(propertyId, { status: newStatus });
      if (res.status !== 200) {
        throw new Error("Failed to update property status.");
      }
      alertSuccess({
        customMessage: "Property status updated successfully.",
      });
    } catch (error) {
      console.error("Error updating property:", error);
      alertError({ customMessage: "Failed to update property status." });

      setProperties((prev) =>
        prev.map((prop) =>
          prop._id === propertyId ? { ...prop, status: prop.status } : prop
        )
      );
    }
  };

  const handleAddActivity = (propertyId, selectedAction = {}) => {
    setCurrentPropertyId(propertyId);
    setCurrentAction(selectedAction);
    setShowPopUp(true);
  };

  const handleShowMore = () => {
    console.log("SHOW MORE");
    const nextPage = page + 1;
    setPage(nextPage);
    fetchProperties(nextPage, filters);
  };

  const handleFiltersChange = (newFilters) => {
    console.log("NEW FILTERS", newFilters);
    setFilters(newFilters);
  };

  return (
    <Card>
      <CardHeader title="Pipeline">
        <CardHeaderToolbar>
          <FiltersCard
            setFilters={handleFiltersChange}
            localidades={localidades}
            users={users}
            handleSelectAll={() => {}}
            handleClearFilters={() => setFilters({ active: true })}
          />
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {error && (
          <Typography color="error" variant="body1" gutterBottom>
            {error}
          </Typography>
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <InfiniteScroll
            dataLength={properties.length}
            next={handleShowMore}
            hasMore={properties.length < total}
            loader={
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <CircularProgress />
              </div>
            }
            endMessage={
              <Typography
                variant="body2"
                align="center"
                style={{ marginTop: "20px" }}
              >
                No more properties to display.
              </Typography>
            }
            style={{ overflowY: "hidden" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "flex-start",
              }}
            >
              {pipelineStates.map((state) => (
                <div
                  key={state._id}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "300px",
                    margin: "0 10px",
                    padding: "10px",
                    backgroundColor: state.color,
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    overflowY: "hidden",
                  }}
                >
                  <h4>{state.name}</h4>
                  <Droppable droppableId={state._id}>
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDraggingOver
                            ? "#e3f2fd"
                            : "#f8f9fa",
                          padding: 8,
                          width: "100%",
                          minHeight: 300,
                          borderRadius: "4px",
                          transition: "background-color 0.2s ease",
                        }}
                      >
                        {columns[state._id]?.items.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  marginBottom: 8,
                                  boxShadow: snapshot.isDragging
                                    ? "0 2px 8px rgba(0,0,0,0.2)"
                                    : "0 1px 2px rgba(0,0,0,0.1)",
                                }}
                              >
                                <PropertyCard
                                  property={properties.find(
                                    (property) => property._id === item.id
                                  )}
                                  onAddActivity={handleAddActivity}
                                  usersGroup={users}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              ))}
            </div>
          </InfiniteScroll>
        </DragDropContext>
        {/* Commercial Activities Popup */}
        <CommercialActivitiesPopUp
          show={showPopUp}
          onHide={() => {
            setShowPopUp(false);
          }}
          action={currentAction}
          propertyId={currentPropertyId}
        />
      </CardBody>
    </Card>
  );
}
