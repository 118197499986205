import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Dialog } from "@material-ui/core";
import "./ImageCarouselDialog.css";

function ImageCarouselDialog({ open, setOpen, images }) {
  const handleClose = () => {
    setOpen(false);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      PaperProps={{
        className: "dialogContent",
      }}
    >
      <div className="img-slider-container">
        <Slider {...settings}>
          {images && images.length > 0 ? (
            images.map((image, index) => (
              <div key={index} className="img-slide">
                <img
                  src={image}
                  alt={`Property Slide ${index}`}
                  className="slider-img"
                />
              </div>
            ))
          ) : (
            <div className="img-slide">
              <img
                src="/media/dummie-house.jpg"
                alt="No Images Available"
                className="slider-img"
              />
            </div>
          )}
        </Slider>
      </div>
    </Dialog>
  );
}

export default ImageCarouselDialog;
