import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Tooltip,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { updateProperty } from "../../../../api/property";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  dialogTitle: {
    position: "relative",
    paddingRight: theme.spacing(4),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  autocomplete: {
    width: 300,
    marginTop: theme.spacing(2),
    backgroundColor: "#fff",
    borderRadius: 4,
    boxShadow: theme.shadows[1],
  },
  dialogActions: {
    justifyContent: "center",
    padding: theme.spacing(2),
  },
}));

export default function LateralWindowUser({
  isOpen,
  onClose,
  properties,
  users,
}) {
  const classes = useStyles();
  const [userAssigned, setUserAssigned] = useState(null);
  const user = useSelector((store) => store.authentication?.user);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  const handleAssign = () => {
    if (!userAssigned) {
      alertError("Please select a user to assign.");
      return;
    }

    const propertiesToUpdate = Array.from(properties).map((propertyId) => ({
      property: propertyId,
      assignedUser: userAssigned._id,
    }));

    Promise.all(
      propertiesToUpdate.map((p) =>
        updateProperty(p.property, { assignedUser: p.assignedUser })
      )
    )
      .then(() => {
        alertSuccess("Properties assigned successfully.");
        onClose();
      })
      .catch((err) => {
        console.error("Error updating properties:", err);
        alertError("Failed to assign properties.");
      });
  };

  if (!isOpen) return null;

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <h5>Assign Properties to User</h5>
        <Tooltip title="Close">
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CancelIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <h6>Assign selected properties to a user:</h6>
        <Autocomplete
          options={users}
          getOptionLabel={(option) => option.fullName || "Unnamed User"}
          value={userAssigned}
          onChange={(event, newValue) => {
            setUserAssigned(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Select User" variant="outlined" />
          )}
          className={classes.autocomplete}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={onClose}
          className="btn btn-primary"
          color="secondary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={handleAssign}
          className="btn btn-primary"
          variant="contained"
        >
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
}
