import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { Button, IconButton } from "@material-ui/core";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import Table, {
  SelectColumnFilter,
} from "../../../components/customTable/Table";
import { alertError, alertSuccess } from "../../../../utils/logger";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import EventIcon from "@material-ui/icons/Event";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import CommercialActivitiesPopUp from "../../../components/comercialActivities/ComercialActivitiesPopUp";
import FullCalendarView from "../../../components/comercialActivities/FullCalendarView";
import {
  getAccionesComercialesByUserId,
  updateAccionComercial,
  deleteAccionComercial,
} from "../../../../api/accionComercial";
import Tooltip from "@material-ui/core/Tooltip";

const AccionesComerciales = () => {
  const user = useSelector((state) => state.authentication.user);
  const [commercialActions, setCommercialActions] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [currentPropertyId, setCurrentPropertyId] = useState(null);
  const [currentAction, setCurrentAction] = useState({});
  const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
  const [actionToDelete, setActionToDelete] = useState(null);
  const [listView, setListView] = useState(true);

  // Pagination states
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [total, setTotal] = useState(0);

  const fetchCommercialActions = useCallback(
    async (pageNumber = 1, size = 25) => {
      try {
        console.log(user._id);
        const res = await getAccionesComercialesByUserId(user._id, {
          page: pageNumber,
          limit: size,
        });
        if (res.status === 200) {
          console.log(res.data);
          setCommercialActions(res.data.data);
          setTotal(res.data.total);
          const urlParams = new URLSearchParams(window.location.search);
          const actionId = urlParams.get("actionId");
          if (actionId && res.data.data.length > 0) {
            const action = res.data.data.find(
              (action) => action._id === actionId
            );
            if (action !== undefined) {
              setCurrentAction(action);
              setCurrentPropertyId(action?.propiedad?._id || null);
              setShowPopUp(true);
            }
          }
        }
      } catch (error) {
        alertError({ customMessage: error.message || "Error fetching data" });
      }
    },
    [user._id]
  );

  useEffect(() => {
    fetchCommercialActions(page, pageSize);
  }, [fetchCommercialActions, page, pageSize]);

  const toggleEstado = useCallback(async (accionId) => {
    setCommercialActions((currentActions) => {
      const index = currentActions.findIndex(
        (action) => action._id === accionId
      );
      if (index === -1) {
        console.error("Action not found with ID:", accionId);
        return currentActions;
      }

      const updatedActions = [...currentActions];
      updatedActions[index].estado =
        updatedActions[index].estado === "pendiente"
          ? "completado"
          : "pendiente";

      updateAccionComercial(accionId, { estado: updatedActions[index].estado })
        .then((res) => console.log("Updated action:", res.data))
        .catch((error) => console.error(error));

      return updatedActions;
    });
  }, []);

  const handleDelete = useCallback((accionId) => {
    setActionToDelete(accionId);
    setOpenConfirmDialog(1);
  }, []);

  const handleEdit = useCallback((action) => {
    setCurrentAction(action);
    setCurrentPropertyId(action?.propiedad?._id || null);
    setShowPopUp(true);
  }, []);

  const handleNew = useCallback(() => {
    setCurrentAction({});
    setCurrentPropertyId(null);
    setShowPopUp(true);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Estado",
        id: "toggle-estado",
        accessor: "estado",
        Filter: SelectColumnFilter,
        Cell: ({ row }) => (
          <input
            type="checkbox"
            style={{ cursor: "pointer" }}
            checked={row.original.estado === "completado"}
            onChange={() => toggleEstado(row.original._id)}
            aria-label={`Toggle estado for ${row.original.nombre}`}
          />
        ),
      },
      {
        Header: "Nombre",
        accessor: "nombre",
        Cell: ({ value }) => (
          <Tooltip title={value} arrow>
            <span
              style={{
                display: "inline-block",
                maxWidth: "150px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                cursor: "pointer",
              }}
            >
              {value}
            </span>
          </Tooltip>
        ),
      },
      {
        Header: "Descripción",
        accessor: "notas",
      },
      {
        Header: "Fecha de Inicio",
        accessor: "fechaInicio",
      },
      {
        Header: "Hora de inicio",
        accessor: "horaInicio",
      },
      {
        Header: "Fecha de Fin",
        accessor: "fechaFin",
      },
      {
        Header: "Hora de Fin",
        accessor: "horaFin",
      },
      {
        Header: "Propiedad",
        accessor: "propiedad.title",
        Filter: SelectColumnFilter,
        filter: "includes",
        Cell: ({ value }) => (
          <Tooltip title={value} arrow>
            <span
              style={{
                display: "inline-block",
                maxWidth: "150px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                cursor: "pointer",
              }}
            >
              {value}
            </span>
          </Tooltip>
        ),
      },
      {
        Header: "Actions",
        id: "actions",
        accessor: (str) => "actions",
        Cell: ({ row }) => (
          <div className="d-flex flex-row">
            <IconButton
              onClick={() => handleEdit(row.original)}
              aria-label={`Edit ${row.original.nombre}`}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleDelete(row.original._id)}
              aria-label={`Delete ${row.original.nombre}`}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        ),
      },
    ],
    [toggleEstado, handleEdit, handleDelete]
  );

  useEffect(() => {
    if (!showPopUp) {
      fetchCommercialActions(page, pageSize);
    }
  }, [showPopUp, fetchCommercialActions, page, pageSize]);

  return (
    <Card>
      <CardHeader title="Lista de Acciones Comerciales">
        <CardHeaderToolbar>
          <section className="d-flex flex-row" style={{ marginRight: "50px" }}>
            <IconButton
              onClick={() => setListView(true)}
              aria-label="List View"
            >
              {listView ? (
                <FormatListBulletedIcon style={{ color: "orange" }} />
              ) : (
                <FormatListBulletedIcon />
              )}
            </IconButton>
            <IconButton
              onClick={() => setListView(false)}
              aria-label="Calendar View"
            >
              {listView ? (
                <EventIcon />
              ) : (
                <EventIcon style={{ color: "orange" }} />
              )}
            </IconButton>
          </section>
          <Button
            variant="contained"
            className="btn btn-primary"
            onClick={handleNew}
            aria-label="Add New Action"
          >
            Añadir nueva
          </Button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {listView ? (
          Array.isArray(commercialActions) ? (
            <Table
              columns={columns}
              data={commercialActions}
              origin={"acciones-comerciales"}
              enableSelection={false}
              page={page}
              pageSize={pageSize}
              total={total}
              onPageChange={setPage}
              onPageSizeChange={setPageSize}
            />
          ) : (
            <span>Loading data...</span>
          )
        ) : (
          <FullCalendarView
            accionesComerciales={commercialActions}
            setShow={setShowPopUp}
            setCurrentAction={setCurrentAction}
            setCurrentPropertyId={setCurrentPropertyId}
          />
        )}
        <CommercialActivitiesPopUp
          show={showPopUp}
          onHide={() => setShowPopUp(false)}
          action={currentAction}
          propertyId={currentPropertyId}
          fetchCommercialActions={fetchCommercialActions}
        />
      </CardBody>
      <ConfirmDialog
        title={
          "¿Estás seguro de que deseas eliminar la siguiente acción comercial?"
        }
        open={openConfirmDialog === 1}
        setOpen={setOpenConfirmDialog}
        onConfirm={() => {
          deleteAccionComercial(actionToDelete)
            .then((res) => {
              if (res.status === 204 || res.status === 200) {
                setOpenConfirmDialog(null);
                setCommercialActions((currentActions) =>
                  currentActions.filter(
                    (action) => action._id !== actionToDelete
                  )
                );
              }
            })
            .catch((error) => {
              alertError({
                error: error,
                customMessage: "Could not remove action.",
              });
            });
        }}
      />
    </Card>
  );
};

export default AccionesComerciales;
